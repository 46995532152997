<template>
    <v-container fluid class="py-0">
        <v-row>
            <v-col sm="12" cols="12" class="py-0">
                <LoadingMe
                    :isLoading="showLoading"
                    :fullPage="true"
                    :myLoading="true"
                />
                <v-row>
                    <v-text-field
                        v-model="phoneSearch"
                        outlined
                        :placeholder="$t('Phone Number...')"
                        clearable
                    />
                    <v-btn icon @click="search" class="btn_search ml-2" style="width: 5%;">
                        <v-icon style="font-size: 32px;color: #fff;">search</v-icon>
                    </v-btn>
                </v-row>
                <v-row >
                    <kendo-datasource
                        ref="gridPreOrder"
                        :type="'JSON'"
                        :data="items"
                        :server-paging="false"/>
                    <kendo-grid
                        id="gridPreOrder" class="grid-function"
                        :data-source-ref="'gridPreOrder'"
                        :editable="false"
                        :groupable="false"
                        :column-menu="true"
                        :noRecords="true"
                        :sortable="true"
                        :excel-filterable="true"
                        :excel-all-pages="true"
                        :scrollable-virtual="true">
                        <kendo-grid-column
                            :title="$t('no.')"
                            :width="40"
                            :template="rowNumber"
                            :column-menu="false"
                            :headerAttributes="{style: 'background-color: #EDF1F5;',class: 'text-products px-1' }"
                            :attributes="{ style: 'text-align: products'}"
                        />
                        <kendo-grid-column
                            :field="'phoneNumber'"
                            :title="$t('phone')"
                            :width="150"
                            :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                        <kendo-grid-column
                            :field="'txnNumber'"
                            :title="$t('number')"
                            :width="150"
                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                        <kendo-grid-column
                            :field="'issuedDate'"
                            :title="$t('issue_date')"
                            :width="150"
                            :template="'#=kendo.toString(new Date(issuedDate), `dd-MM-yyyy`)#'"
                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                        <kendo-grid-column
                            :field="'total'"
                            :title="$t('amount')"
                            :width="150"
                            :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                        <kendo-grid-column
                                :command="{
                                    text: recieveText,
                                    click: closePreOrder,
                                    className: 'btn-plus isEditable',
                                }"
                                :title="$t('action')"
                                :width="150"
                                :headerAttributes="{
                                style: 'text-align: left; background-color: #EDF1F5',
                                }"
                            />
                    </kendo-grid>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { i18n } from '@/i18n';
import kendo from "@progress/kendo-ui"
const $ = kendo.jQuery
const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
const cookieJS = require("@/cookie.js");
const { instituteId } = cookieJS.getCookie()
//indexeddb
// const console = window.console
// 1
const indexedDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

if (!indexedDB) {
  window.console.log("IndexedDB could not be found in this browser.");
}
// 2
const request = indexedDB.open(instituteId, 1);
request.onerror = function (event) {
  window.console.error("An error occurred with IndexedDB");
  window.console.log(event, 'error');
};
let db;
request.onupgradeneeded = function () {
  //1
  db = request.result;
  db.createObjectStore(instituteId + "items", { keyPath: "id" });
  db.createObjectStore(instituteId + "itemPrice", { keyPath: "id" });
  db.createObjectStore(instituteId + "employee", { keyPath: "id" });
  db.createObjectStore(instituteId + "modifier", { keyPath: "id" });
  db.createObjectStore(instituteId + "campaign", { keyPath: "id" });
  db.createObjectStore(instituteId + "serving", { keyPath: "pk" });
  db.createObjectStore(instituteId + "invoices", { keyPath: "pk" });
  db.createObjectStore(instituteId + "preorders", { keyPath: "pk" });
};
let loadIndex = false
request.onsuccess = function () {
    loadIndex = true
    db = request.result;
};
export default {
    name: "PreOrder",
    props: {preorders: []},
    data: () => ({
        items: [],
        phoneSearch: '',
        kendo: kendo,
        showLoading: false,
        register: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')) : {},
    }),  
    methods: {
        delIndxdb(key, tbname){
            if(loadIndex){
                const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
                const request = objectStore.delete(key);
                // let self = this
                request.onsuccess = ()=> {
                    window.console.log('db deleted!')
                    // self.bindServing()
                }
            }
        },
        closePreOrder(e) {
            e.preventDefault();
            const grid = $("#gridPreOrder").data("kendoGrid"),
                dataItem = grid.dataItem($(e.currentTarget).closest("tr"));
            window.console.log(dataItem, 'close pre  order')
            this.showLoading = true
            let data = {
                gsi2: 'updatepreorder',
                pk: dataItem.pk,
                register: this.register.pk,
                key: {}
            }
            commerceHandler.getGsi2(data).then(res=>{
                window.console.log(res, 'seve res')
                this.showLoading = false
                this.delIndxdb(dataItem.pk, 'preorders')
                const index = this.items.indexOf(dataItem);
                this.items.splice(index,1);
            })
        },
        rowNumber(dataItem) {
            let ds = this.$refs.gridPreOrder.kendoWidget(),
                index = ds.indexOf(dataItem);
            return index + 1;
        },
        search(){
            if(this.phoneSearch == ''){
                this.items = this.preorders
            }else{
                let pres = []
                this.preorders.forEach(pr=>{
                    let phone = pr.phoneNumber || ''
                    if(phone != ''){
                        // window.console.log(phone, this.phoneSearch)
                        if(phone.indexOf(this.phoneSearch) !== -1){
                            pres.push(pr)
                        }
                    }
                })
                this.items = pres
            }
        }
    },
    components: {
        LoadingMe: () => import(`@/components/Loading`)
    },
    computed:{
        recieveText(){
            return i18n.t('close_preorder')
        }
    },
    created: async function () {
        this.search()
    },
    
};
</script>
<style scoped>
    #pin > .theme--light.v-input input {
        max-height: 32px;
        font-size: 2rem !important;
        text-align: center;
    }
    .v-image__image--cover {
        background-size: contain;
        margin-top: 5px;
    }
    .v-image__image {
        background-position: top center !important;
    }
    .b-cash:before {
        color: #ffffff;
    }   
    .b-loyalty:before {
        content: "\e91b";
        color: #ffffff;
    }
    .btn-right .v-btn__content i{
       font-size: 40px;
    }
    .btn-right .v-btn__content{
       display: block !important;
    }
    h6{
       color: #2a2b2b;
       font-size: 0.6rem !important; 
       font-family: "Niradei-bold", serif !important;
       padding-top: 5px;
    }
    
    .btn-right{
        background-color: #ffffff !important;
        border-bottom: 1px solid;
        border-color: #e5e5e6 !important;
        width: 100%;
        height: 12% !important;
        text-align: center;
        padding: 0 3px !important;
        border-radius: 0px !important;  
    }
    .v-tab {
        justify-content: left;
        font-size: 20px;
    }

    .v-tab--active {
        background-color: rgb(255, 255, 255);
    }

    .tab_setting .v-tab--active {
        font-weight: 700;
        color: #000;
    }

    .v-tab--active {
        background-color: #ffffff !important;
        border-bottom: 4px solid #92d050;
        border-left: none;
    }

    p {
        color: rgba(0, 0, 0, 0.87);
    }
    .theme--light.v-tabs-items {
        background-color: #FFFFFF;
        height: 550px !important;
        overflow: scroll !important;
    }
    
    .sale-container{
        max-width: 1870px !important;
    }
    .theme--light.v-tabs-items {
        background-color: #ffffff00 !important;
    }
    .card-item{
        background-color: #ffffff00;
        border: 0.2px solid #dcdcdc33;
        border-radius: 0;
    }
    .catagory-btn{
        width: 100%;
        background-color: #ffffff !important;
        border: 0.2px solid #e4e4e6;
    }
    .v-btn__content{
        font-family: "Niradei-Bold", serif !important;
        display: block !important;
    }
    .btn_h2{
        font-size: 22px;
    }
    .sidebar-left{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 12.666667%;
        flex: 0 0 12.666667%;
        max-width: 12.666667%;
    }
    .sidebar-left2{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 38.666667%;
        flex: 0 0 38.666667%;
        max-width: 38.666667%;
    }
    .sidebar-left3{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 7.000000%;
    flex: 0 0 7.000000%;
    max-width: 7.000000%;
    }
    .container h2 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .btn-sidebar{
        justify-content: space-between !important;
        background-color: #fff !important;
        font-family: "Niradei-bold", serif !important;
    }

    .b-payment:before {
        content: "\e91f";
        color: #ffffff;
    }
    .b-banking:before {
        content: "\e90e";
        color: #ffffff;
    }
    .btn-money {
        background-color: #969696 !important;
        color: white;
    }
    
    .calculator{
        border: 1px solid gainsboro;
    }
    .receipt-btn{
        min-width: 22% !important;
        height: 50px !important;
        padding: 10px !important;
        margin: 5px;
    }
    .apply-btn{
        min-width: 33% !important;
        height: 50px !important;
        padding: 5px !important;
        margin: 5px;
    }
    .calculator-btn{
       min-width: 18% !important;
        margin: 4px;
        padding: 0 10px !important;
    }
    .calculator-btn-1{
        min-width: 20% !important;
        margin: 4px;
        padding: 0 2px !important;
        font-size: 13px !important;
        letter-spacing: inherit;
        font-weight: bold;
    }
    .pay-btn{
        width: 100%;
        border: 1px solid #e0e0e0;
    }
    .v-text-field__detail .field-pos{
        display:none !important;
    }
    .btn-funtion{
        font-size: 14px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
    }
    .function_content {
        padding: 0px;
        border-bottom: none !important;
        background-color: #f8f8f9;
    }
    .notification{
        background-color: #ed263a;
        width: auto;
        margin-left: 2px;
        color: #fff;

    }
    .v-btn.v-size--default {
        font-size: 1.2rem;
    }
    .b-search.b:before {
        color: #d7d3d3 !important;
    }
    /* pin */
    body {
      height: 95vh;
      background-color: #181c26 !important;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Open Sans;
    }

    body.wrong {
      -webkit-animation: bg-red 1s ease-in;
      animation: bg-red 1s ease-in;
    }

    body.correct {
      -webkit-animation: bg-green 1s ease-in;
      animation: bg-green 1s ease-in;
    }

    #inspiration {
      position: fixed;
      right: 1em;
      bottom: 1em;
    }

    #inspiration a {
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      color: white;
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
    }

    #inspiration a:hover { color: #212121; }

    #inspiration p {
      margin: 0;
      padding-left: .4em;
      display: inline-block;
      color: rgba(255, 255, 255, 0.6);
    }
    #pin {
        background-color: #ffffff !important;
        width: 90%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* padding: 1em; */
        border-radius: .3em;
        /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); */
        margin: auto;
        color: rgb(155 27 46);;
        }

    .dots {
      width: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      padding: 1em;
      padding-top: 3em;
    }

    .dot {
      position: relative;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.8em;
      width: 0.8em;
      height: 0.8em;
      -webkit-transform: scale3d(0.7, 0.7, 0.7);
      transform: scale3d(0.7, 0.7, 0.7);
    }

    .dot.active {
      -webkit-animation: growDot .5s ease;
      animation: growDot .5s ease;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .dot.wrong {
      -webkit-animation: wrong .9s ease;
      animation: wrong .9s ease;
    }

    .dot.correct {
      -webkit-animation: correct .9s ease;
      animation: correct .9s ease;
    }

    .cancelPin {
      width: 25%;
      margin-left: 10%;
      margin-top: 10%;
    }
    .letter_spacing{
        letter-spacing:initial;
        font-size: 12px !important;
    }

    #pin p { font-size: 1.2em; }

    .numbers {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
      margin: 1em 0;
    }

    .number {
      position: relative;
      width: 2.5em;
      height: 2.5em;
      margin: 0.5em;
      border-radius: 2.5em;
      border: 2px solid rgb(154 27 46);
      text-align: center;
      line-height: 2.5em;
      font-weight: 400;
      font-size: 1.8em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      cursor: pointer;
    }

    .number:hover { color: rgba(243, 134, 134, 0.5); }

    .number:hover:before { border: 2px solid rgba(255, 255, 255, 0.5); }

    .number:before {
      content: "";
      position: absolute;
      left: -2px;
      width: 2.5em;
      height: 2.5em;
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: 2.5em;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
    }
    .number.grow:before {
        -webkit-animation: grow .6s ease;
        animation: grow .6s ease;
        }
    @-webkit-keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @-webkit-keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @-webkit-keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @-webkit-keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @-webkit-keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @-webkit-keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    @keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    #pin >.v-input input {
        text-align: center !important;
        font-size: 35px !important;
    }
    .td-invioce td{
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .tb-pong td{
        border-bottom: none !important;
        height: 25px !important;
    }
    .th-invoice th{
      border-top: thin solid rgba(0, 0, 0, 0.12) !important;  
    }
    .v-list-item-left {
        padding: 0 10px !important;
    }
    .theme--light.v-divider{
        border-color: rgb(0 0 0 / 0%);
    }


    /* Surface pro */
    @media only screen and (min-width : 1400px){
        .btn-funtion{
            font-size: 14px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 8px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 18px;
            height: 18px;
            width: 18px;
        }
        .calculator-btn{
            font-size: 14px;
        }
        
    }
   @media only screen and (min-device-height : 720px) and (max-device-width : 1280px) {
    
        /** Surface Pro styles here **/
        .btn-funtion{
            font-size: 12px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 5px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 16px;
            height: 10px;
            width: 10px;
        }
        .calculator-btn{
            font-size: 12px;
        }
    }
    .angle_action {
        right: 0;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .angle_action_small {
        right: 12px;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .b-order_type:before {
        content: "\e933";
        color: #ffffff;
        font-size: 22px;
    }
    .b-reward_s:before {
        content: "\e937";
        color: #ffffff;
        font-size: 22px;
    }
    .b-promotion:before {
        content: "\e936";
        color: #ffffff;
        font-size: 22px;
    }
    .b-count_guest:before {
        content: "\e935";
        color: #ffffff;
        font-size: 22px;
    }
    .b-note_s:before {
        content: "\e932";
        color: #ffffff;
        font-size: 22px;
    }
    .b-delivery_s:before {
        content: "\e931";
        color: #ffffff;
        font-size: 22px;
    }
    .b-parksale:before {
        content: "\e934";
        color: #ffffff;
        font-size: 22px;
    }
    .b-invoice_s:before {
        content: "\e930";
        color: #ffffff;
        font-size: 22px;
    }

</style>